import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Routes } from '../constants/Routes';
import Title from '../components/title';
import Image from 'gatsby-image';
import { IFluidObject } from 'gatsby-background-image';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import Lightbox from '../components/Lightbox';
import renderHtml from '../utils/renderHtml';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const CIE_QUERY = graphql`
  {
    allContentfulCieAhaPlus {
      nodes {
        id
        titel
        beschreibung {
          json
        }
        bilder {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;

const CieAhaPlusPage: FC = () => {
  const data = useStaticQuery(CIE_QUERY).allContentfulCieAhaPlus.nodes[0];
  return (
    <Layout location={Routes.CIE_AHA_PLUS}>
      <SEO title="cie. aha! plus" />
      <div className="mt-8">
        <Title className="mb-4 text-center">cie. aha! plus</Title>
        <div className="transition-all transition-250 hover:shadow-xl max-w-6xl rounded overflow-hidden shadow-lg mx-auto mb-12 w-full p-4 md:p-8">
          <Lightbox
            galleryButtonVisible={false}
            showLeftRightButtons={true}
            youtubeUrls={[]}
            images={data.bilder.map(b => b.fluid)}
          />
          <div className="mt-8 markdown-body">{documentToReactComponents(data.beschreibung.json)}</div>
        </div>
      </div>
    </Layout>
  );
};

export default CieAhaPlusPage;
